// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '@src/services/api'

export const setAlertTrigger = createAsyncThunk('appQuestionnaires/setAlertTrigger', async (toggle, { dispatch, getState }) => {
  return toggle
})

export const updateAlert = createAsyncThunk('appQuestionnaires/updateAlert', async (props, { dispatch, getState }) => {
  return { text: props.text, variant: props.variant }
})

export const getData = createAsyncThunk('appQuestionnaires/getData', async params => {
  const licenca = JSON.parse(localStorage.getItem("userData"))

  const response = await axios.get(`${process.env.REACT_APP_API_URL}api/questionariopaciente/list?offset=${params?.page}&clientId=${licenca.uuid}&limit=${params?.perPage}&orderBy=nome::asc`)

  return {
    params,
    data: response?.data?.questionario_paciente,
    totalPages: response.data.ordenate.totalItem
  }
})

export const getCurrentQuestionnaire = createAsyncThunk('appQuestionnaires/getCurrentQuestionnaire', async params => {
  const licenca = JSON.parse(localStorage.getItem("userData"))

  const response = await axios.get(`${process.env.REACT_APP_API_URL}api/questionariopaciente/search?offset=0&clientId=${licenca.id}&limit=10&orderBy=nome::asc&search=${params.uuid}`)
 
  return {
    step: 1,
    // data: response?.data?.questionario_paciente[0]
    data: response?.data
  }
})

export const updateCurrentRehabilitationPacient = createAsyncThunk('appQuestionnaires/updateCurrentRehabilitationPacient', async (currentRehabilitationPacient, { dispatch, getState }) => {
  return currentRehabilitationPacient
})

export const updateCurrentQuestionnaire = createAsyncThunk('appQuestionnaires/updateCurrentQuestionnaire', async (currentQuestionnaire, { dispatch, getState }) => {
  return currentQuestionnaire
})

export const getCurrentQuestionnaireModel = createAsyncThunk('appQuestionnaires/getCurrentQuestionnaireModel', async id => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}api/modeloquestionario/search?offset=0&limit=10&orderBy=nome::asc&search=${id}`)

  return {
    id,
    data: response.data.modelo_questionario[0],
    totalPages: response.data.ordenate.totalPage
  }
})

export const getCurrentRehabilitation = createAsyncThunk('appQuestionnaires/getCurrentRehabilitation', async cpf => {
  const licenca = JSON.parse(localStorage.getItem("userData"))
  const response = await axios.get(`${process.env.REACT_APP_API_URL}api/reabilitacaopaciente/searchByCpf?offset=0&limit=10&orderBy=nome::asc&search=${cpf}&clientId=${licenca.clientId}`)

  return {
    cpf,
    data: response.data.reabilitacao_paciente[0],
    totalPages: response.data.ordenate.totalPage
  }
})

export const getPacientes = createAsyncThunk('appUsers/getPacientes', async params => {
  const licenca = JSON.parse(localStorage.getItem("userData"))

  const response = await axios.get(`${process.env.REACT_APP_API_URL}api/paciente/listdropdown?clientId=${licenca.clientId}&orderBy=nome::asc`, {
    withCredentials: true
  })
  return {
    params,
    data: response.data.paciente,
    totalPages: response.data.ordenate.totalPage
  }
})

export const addQuestionnairePacient = createAsyncThunk('appQuestionnaires/addQuestionnairePacient', async (questionnaire, { dispatch, getState }) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}api/questionariopaciente/create`, questionnaire)

  await dispatch(updateAlert({ text: "Excelente! Questionário registrado com sucesso", variant: "success" }))
  await dispatch(setAlertTrigger(true))
  await dispatch(getData(getState()))
  return questionnaire
})

export const updateQuestionnaire = createAsyncThunk('appQuestionnaires/updateQuestionnaire', async (questionnaire, { dispatch, getState }) => {
  //Atualizando no backEnd - "questionário paciente"
  await axios.put(`${process.env.REACT_APP_API_URL}api/questionariopaciente/update?uuid=${questionnaire?.questionario_paciente?.uuid}`, questionnaire.questionario_paciente)

  //Atualizando no backEnd - "atividades questionário paciente"
  if (Array.isArray(questionnaire.atividades_questionario) && questionnaire.atividades_questionario.length > 0) {
    await axios.put(`${process.env.REACT_APP_API_URL}api/atividadesquestionariopaciente/update`, questionnaire.atividades_questionario)
  }

  await dispatch(updateAlert({ text: "Alterações realizadas com sucesso", variant: "success" }))
  await dispatch(setAlertTrigger(true))
  await dispatch(getData(getState()))
  return questionnaire
})

export const deleteQuestionnaire = createAsyncThunk('appQuestionnaires/deleteQuestionnaire', async (params, { dispatch, getState }) => {
  //Apagando Questionário
  await axios.put(`${process.env.REACT_APP_API_URL}api/questionariopaciente/delete?uuid=${params.uuid}`)

  await dispatch(getData(getState()))
  await dispatch(updateAlert({ text: "Questionário excluído com sucesso", variant: "danger" }))
  await dispatch(setAlertTrigger(true))
})

export const appQuestionnairesSlice = createSlice({
  name: 'appQuestionnaires',
  alertProps: { text: "", variant: "" },
  alertTrigger: false,
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    currentModelQuestionnaire: {},
    currentRehabilitationPacient: {},
    currentQuestionnaire: {
      step: 1,
      showModal: true,
      data: {}
    }
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(updateAlert.fulfilled, (state, action) => {
        state.alertProps = action.payload
      })
      .addCase(setAlertTrigger.fulfilled, (state, action) => {
        state.alertTrigger = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getPacientes.fulfilled, (state, action) => {
        state.pacientes = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(updateCurrentQuestionnaire.fulfilled, (state, action) => {
        state.currentQuestionnaire = action.payload
      })
      .addCase(updateCurrentRehabilitationPacient.fulfilled, (state, action) => {
        state.currentRehabilitationPacient = action.payload
      })
      .addCase(getCurrentQuestionnaireModel.fulfilled, (state, action) => {
        state.currentModelQuestionnaire = action.payload
      })
      .addCase(getCurrentQuestionnaire.fulfilled, (state, action) => {
        state.currentQuestionnaire = action.payload
      })
  }
})

export default appQuestionnairesSlice.reducer